import React, { Component } from 'react'
import _ from 'lodash'
import Product from './Product'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { actions } from '.'
const Products = ({ value, onProductClick }) => {
  return (
    <div className="products">
      {_.map(value, (v, i) => {
        return (
          <Product key={i + v.name} value={v} onClick={() => onProductClick(v)} />
        )
      })}
    </div>
  )
}

let applyCategoriesFilter = _.memoize(
  (products, categories) => {
    let filteredProducts = _(products)
      .chain()
      .filter(a => (categories.length > 0 ? categories.includes(a.category) : true))
      .value()

    return filteredProducts
  },
  (products, categories) => {
    let a = _(categories)
      .chain()
      .sort()
      .join(',')
      .value() || 'empty'

    console.log('a', a)
    return products.length + a
  },
)

function mapStateToProps(state) {
  const {
    products: { products, filters },
  } = state

  return {
    value: applyCategoriesFilter(products, filters.categories),
  }
}

function mapDispatchToProps(dispatch) {
  let a = bindActionCreators(actions, dispatch)
  return {
    onProductClick: a.productClicked,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Products)
