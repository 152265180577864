import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import { connect } from 'react-redux'
import './ProductsSummary'
import { actions } from '.'

class ProductsFilters extends React.Component {
  render() {
    let {
      filters = {},
      value: { categories, categoryAggregateCounts } = {},
      className,
      categoryClicked,
      children,
    } = this.props

    className = classNames(className, 'products-filters')

    return (
      <div className={className}>
        <div className="products-filters-categories">
          <span>Categories</span>
          <span>
            {_.map(categories, (v, i) => {
              let tmp = classNames({
                selected: filters.categories.includes(v),
              })
              let count = categoryAggregateCounts[v]
              return (
                <span className={tmp} onClick={() => categoryClicked(v)} key={v}>
                  <span>{v}</span>
                  <span>({count})</span>
                </span>
              )
            })}
          </span>
        </div>
        {/* <div>
          <span>Price range</span>
          <span />
        </div> */}
        {children}
      </div>
    )
  }
}

const ProductsFiltersContainer = connect(
  state => {
    const {
      products: { categories, filters, categoryAggregateCounts },
    } = state

    return {
      value: {
        categories: categories,
        categoryAggregateCounts: categoryAggregateCounts
      },
      filters: filters,
    }
  },
  {
    categoryClicked: actions.filterCategoryClicked,
  },
)(ProductsFilters)

export default ProductsFiltersContainer
