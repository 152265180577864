import _ from 'lodash';
import moment from 'moment';
import { createAction, handleActions } from 'redux-actions';
import { ofType } from 'redux-observable';
import { mapTo, mergeMap, map, catchError, subscribeOn } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import dot from 'dot-prop-immutable-chain';
import { of } from 'rxjs';
import { BACKEND_URL } from '../config';

export const actions = {
  historyPush: createAction('HISTORY_PUSH', v => ({})),
  historyPushed: createAction('HISTORY_PUSHED', v => ({})),
  historyReplace: createAction('HISTORY_REPLACE', v => ({})),
  historyReplaced: createAction('HISTORY_REPLACED', v => ({})),
  loadProductsClicked: createAction('LOAD_PRODUCTS_CLICKED', v => ({})),
  loadProducts: createAction('LOAD_PRODUCTS', v => ({})),
  productsLoaded: createAction('PRODUCTS_LOADED', response => ({ response: response })),
  loadProductsFailed: createAction('LOAD_PRODUCTS_FAILED', error => ({ error })),
  filterCategoryClicked: createAction('FILTER_CATEGORY_CLICKED', v => ({ category: v })),
  productsFiltersUpdated: createAction('PRODUCTS_FILTERS_UPDATED', v => ({ filters: v })),
  productClicked: createAction('PRODUCT_CLICKED', v => ({ product: v })),
  productDetailsLoaded: createAction('PRODUCT_DETAILS_LOADED', v => ({ product: v })),
};

export const epics = {
  // loadProducts: action$ => {
  //   console.log('action$', action$);
  //   return action$.pipe(
  //     ofType(actions.loadProducts().type),
  //     mergeMap(action =>
  //       ajax
  //         .getJSON(BACKEND_URL + `/woolworths/products`)
  //         .pipe(map(response => actions.productsLoaded(response)))
  //         .catch(e => of(actions.loadProductsFailed(e))),
  //     ),
  //     catchError(e => of(actions.loadProductsFailed(e))),
  //   );
  // },
};

let initialState = {
  products: [],
  lastResponse: {},
  lastUpdatedAt: '',
  categories: [],
  productDetails: {},
  filters: {
    categories: [],
  },
  loadings: {
    loadingProducts: false,
  },
};

export default handleActions(
  new Map([
    [
      actions.loadProducts,
      (state, { paylaod }) => {
        return dot(state)
          .set('loadings.loadingProducts', true)
          .value();
      },
    ],
    [
      actions.productsLoaded,
      (state, { payload: { response } }) => {
        let products = _(response.Bundles)
          .chain()
          .map(v => {
            let prods = _.map(v.Products, a => {
              return {
                name: a.Name,
                detailsImagePaths: a.DetailsImagePaths,
                mediumImageFile: a.MediumImageFile,
                savingsAmount: a.SavingsAmount,
                price: a.Price,
                richDescription: a.RichDescription,
                id: a.stockCode,
                category:
                  _(a)
                    .chain()
                    .get('AdditionalAttributes.sapsubcategoryname')
                    .replace(/\W-.*/g, '')
                    .capitalize()
                    .value() || 'Others',
                // _.capitalize(_.get(a, 'AdditionalAttributes.sapsubcategoryname') || 'Others'),
                brand: _.capitalize(a.Brand),
              };
            });

            return prods;
          })
          .flatten()
          .filter(a => a.price)
          .orderBy('savingsAmount', 'desc')
          .value();

        let categoryAggregateCounts = {};

        let categories = _(products)
          .chain()
          .map(a => {
            categoryAggregateCounts[a.category] = categoryAggregateCounts[a.category]
              ? categoryAggregateCounts[a.category] + 1
              : 1;

            return a.category;
          })
          .uniq()
          .sortBy()
          .value();

        return dot(state)
          .set('products', products)
          .set('lastResponse', response)
          .set('lastUpdatedAt', moment().fromNow())
          .set('categories', categories)
          .set('categoryAggregateCounts', categoryAggregateCounts)
          .set('loadings.loadingProducts', false)
          .value();
      },
    ],
    [
      actions.filterCategoryClicked,
      (state, { payload }) => {
        let { category } = payload;

        let categories = state.filters.categories;

        categories = categories.includes(category)
          ? _.filter(categories, a => a !== category)
          : [category];
        return dot(state)
          .set('filters.categories', categories)
          .value();
      },
    ],
    [
      actions.productDetailsLoaded,
      (state, { payload: { product } }) => {
        return dot(state)
          .set('productDetails', product)
          .value();
      },
    ],
    [
      actions.productsFiltersUpdated,
      (state, { payload: {} }) => {
        return dot(state).value();
      },
    ],
  ]),
  initialState,
);
