import React from 'react';
import classNames from 'classnames';
import './Product.scss';

const Product = props => {
  let {
    value: { name, mediumImageFile, detailsImagePaths, savingsAmount, price, variety, brand } = {},
    className,
    ...others
  } = props;

  className = classNames(className, 'product');

  let [first, second] = detailsImagePaths;

  return (
    <div className={className} {...others}>
      <div
        style={{
          'background-image': `url(${mediumImageFile})`,
        }}
        onClick={e => {
          window.open(first, '_blank');
        }}
        // onMouseOver={e => {
        //   if (second) e.currentTarget.src = second
        // }}
        // onMouseLeave={e => {
        //   if (second) e.currentTarget.src = mediumImageFile
        // }}
        alt=""
      />
      <div>{name}</div>
      <div>{brand}</div>
      <div>
        <span>(-${savingsAmount})</span>
        <span>${price}</span>
      </div>
    </div>
  );
};

export default Product;
