import { createStore, applyMiddleware, compose } from 'redux'
import { rootReducer, rootEpic } from '../root'
import { createEpicMiddleware } from 'redux-observable'
import createSagaMiddleware from 'redux-saga'
import allSagas from '../sagas';

const epicMiddleware = createEpicMiddleware()
const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = preloadedState => {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(sagaMiddleware, epicMiddleware),
    ),
  )

  sagaMiddleware.run(allSagas)
  epicMiddleware.run(rootEpic)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../root', () => {
      store.replaceReducer(rootReducer)
    })

    module.hot.accept('../sagas', () => {
      sagaMiddleware.run(allSagas)
    })
  }

  return store
}


export default configureStore
