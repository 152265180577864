import _ from 'lodash';

function BadRequestException(message) {
  this.name = 'badRequest';
  this.message = message || 'Something went wrong';
  this.stack = (new Error()).stack;
}
BadRequestException.prototype = Object.create(Error.prototype);
BadRequestException.prototype.constructor = BadRequestException;

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 400) {
    return response;
  }

  let error = new Error(`Fetch for ${response.url} failed w/ ${response.status}: ${response.statusText}`);
  error.response = response;
  throw error;
};

export const parseJson = response => {
  return response.text().then(function(text) {
    let obj = text ? JSON.parse(text) : {};
    if (response.status === 400 && obj.message && Object.keys(obj).length === 1) {
      throw new BadRequestException(obj.message)
    }

    return obj
  });
};

export const getDelayedPromise = (result, delayInMs = 1000) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(result);
    }, delayInMs);
  });
};

export const jsonToQueryString = json => {
  return `?${_.keys(json)
    .map(key => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`;
    })
    .join('&')}`;
};

export const postJson = (url, data, returnRaw = false) => {
  // return all.getDelayedPromise({});
  return fetch(url, {
    method: 'post',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then(returnRaw ? v => v : parseJson);
};

export const getJson = url => {
  // return all.getDelayedPromise({});
  return fetch(url, {
    method: 'get',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(parseJson);
};

export const deleteRequest = (url, data) => {
  // return all.getDelayedPromise({});
  return fetch(url, {
    method: 'delete',
    mode: 'cors',
  })
    .then(checkStatus)
    .then(parseJson);
};
