import 'normalize.css';
import _ from 'lodash';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import logo from './assets/app-logo.png';
import './App.scss';
import { actions } from './products';
import Products from './products/Products';
import ProductsSummaryContainer from './products/ProductsSummary';
import ProductsFiltersContainer from './products/ProductsFilters';
import Loading from './ui/Loading';
import { Router, Route, Link } from 'react-router-dom';
import createBrowserHistory from "history/createBrowserHistory";

export const history = createBrowserHistory()

const Index = props => {
  return (
    <div>
      {props.loadingProducts ? (
        <Loading />
      ) : (
        <div>
          {<ProductsSummaryContainer />}
          {<ProductsFiltersContainer />}
          {<Products />}
        </div>
      )}
    </div>
  );
};

export const IndexContainer = connect(
  state => {
    const {
      products: {
        loadings: { loadingProducts },
      },
    } = state;

    return {
      loadingProducts: loadingProducts,
    };
  },
  {},
)(Index);



const ProductDetailsPage = ({ product }) => (
  <div>
    <div>{product.name}</div>
  </div>
);

export const ProductDetailsPageContainer = connect(
  (state, { match }) => {
    let id = match.params.id
    let name = match.params.name
    
    const {
      products: {
        productDetails,
        loadings: { loadingProducts },
      },
    } = state;

    return {
      product: productDetails,
      loadingProducts: loadingProducts,
    };
  },
  {},
)(ProductDetailsPage)

const ProductPage = ({ match }) => (
  <div>
    <Route path={`${match.path}/:id/:name`} component={ProductDetailsPageContainer} />
    <Route
      exact
      path={match.path}
      render={() => <h3>Please select a topic.</h3>}
    />
  </div>
);

class App extends Component {
  state = {};
  componentDidMount() {
    this.props.componentDidMount();
  }

  render() {
    let { data = {}, loadingProducts } = this.props;

    return (
      <Router history={history}>
        <div className="App">
          <div className="App-body">
            <header className="App-header">
              {!loadingProducts && <img src={logo} className="App-logo" alt="logo" />}
            </header>
            <Route path="/" exact component={IndexContainer} />
            <Route path="/product" exact component={ProductPage} />
          </div>
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const {
    products: {
      loadings: { loadingProducts },
    },
  } = state;

  return {
    loadingProducts: loadingProducts,
  };
}

function mapDispatchToProps(dispatch) {
  let a = bindActionCreators(actions, dispatch);
  return {
    componentDidMount: a.loadProducts,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
