import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import { connect } from 'react-redux'
import './ProductsSummary'

class ProductsSummary extends React.Component {
  render() {
    let { value: { totalCount, lastUpdatedAt } = {}, className, children } = this.props

    className = classNames(className, 'products-summary')

    return (
      <div className={className}>
        <div>
          There are <span>{totalCount}</span> products. Last loaded <span>{lastUpdatedAt}</span>
        </div>
        {children}
      </div>
    )
  }
}

const ProductsSummaryContainer = connect(
  state => {
    const {
      products: { products, lastUpdatedAt },
    } = state

    return {
      value: {
        totalCount: products.length,
        lastUpdatedAt: lastUpdatedAt,
      },
    }
  },
  {},
)(ProductsSummary)

export default ProductsSummaryContainer
