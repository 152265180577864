import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions } from './products';
import { history } from './App';
import { postJson, getJson } from './apiUtils';
import { BACKEND_URL } from './config';

function* clickProduct({ payload }) {
  yield put(actions.productDetailsLoaded(payload.product));
  yield put(actions.historyPush())
}

function* loadProducts({ payload }) {
  try {
    let data = yield call(getJson, BACKEND_URL + `/woolworths/products`)
    yield put(actions.productsLoaded(data));
  } catch (e) {
      yield put(actions.loadProductsFailed(e))
  }
}

function* pushHistory({ payload }) {
  yield call(history.push, payload.location);
  yield put(actions.historyPushed());
}

function* replaceHistory({ payload }) {
  yield call(history.replace, payload.location);
  yield put(actions.historyReplaced());
}

function* mySaga() {
  yield takeLatest(actions.loadProducts().type, loadProducts);
  yield takeLatest(actions.productClicked().type, clickProduct);
  yield takeLatest(actions.historyPush().type, pushHistory);
  yield takeLatest(actions.historyReplace().type, replaceHistory);
  // yield takeLatest(actions., clickProduct);
}

export default mySaga;
