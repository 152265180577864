import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGhost } from '@fortawesome/free-solid-svg-icons'
import './Loading.scss';
import logo from '../assets/app-logo.png'

class Loading extends React.Component {
  render() {
    let {
      className,
      children,
    } = this.props;

    className = classNames(className, 'loading');

    return (
      <div className={className}>
        {/* <FontAwesomeIcon icon={faGhost} spin size="lg" /> */}
        <img src={logo} alt="loading..." />
        {children}
      </div>
    );
  }
}

Loading.propTypes = {};

Loading.defaultProps = {};

export default Loading;
